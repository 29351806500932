import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import ocupacaoApi from "../../../../../services/ocupacaoApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";
import ViaCepService from "../../../../../services/viaCepApi";

export interface IOcupacaoAusVisitante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function OcupacaoAusVisitante({processo, setProcesso, setTelaNumero, telanumero}: IOcupacaoAusVisitante ) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        ocupacao: '',
        nome_empresa: '',
        cep: '',
        endereco: '',
        cidade: '',
        estado: '',
        telefone: '',
        pais: '',
        data_entrada: '',
        tempo_estudo: '',
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getOcupacao()
    }, [])

    const getOcupacao = async () => {
        const {data, isError} = await ocupacaoApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    async function onSubmit() {
        const {data, isError} = state.id ? await ocupacaoApi.update(state.id, state) : await ocupacaoApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: removeDiacritics(cepResponse.data.logradouro),
                    cidade: removeDiacritics(cepResponse.data.localidade),
                    estado: removeDiacritics(cepResponse.data.uf),
                    pais: 'Brasil',
                });
            }
        });
    }

    function removeDiacritics(value: string): string {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, ''); // Remove diacríticos como acentos e cedilha
    }

    return(
        <>
            <HeaderForm
                titulo={'Ocupação'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            large={true}
                            className={'col-span-1'}
                            label={'Ocupação Atual'}
                            name={'ocupacao'}
                            setValue={e => handleChange(e, 'ocupacao')}
                            value={state.ocupacao}
                        />
                        <Input
                            required={true}
                            large={true}
                            className={'col-span-1'}
                            label={'Nome do empregador ou escola'}
                            name={'nome_empresa'}
                            setValue={e => handleChange(e, 'nome_empresa')}
                            value={state.nome_empresa}
                        />
                        <Input
                            large={true}
                            type={'date'}
                            className={'col-span-1'}
                            label={'Data de início do trabalho ou da aposentadoria'}
                            name={'data_entrada'}
                            setValue={e => handleChange(e, 'data_entrada')}
                            value={state.data_entrada}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            label={'CEP'}
                            name={'cep'}

                            setValue={e => handleChange(e, 'cep')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={state.cep}
                        />
                        <Input
                            required={true}
                            className={'col-span-2'}
                            label={'Endereço'}
                            name={'endereco'}
                            setValue={e => handleChange(e, 'endereco')}
                            value={state.endereco}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Cidade'}
                            name={'cidade'}
                            setValue={e => handleChange(e, 'cidade')}
                            value={state.cidade}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Estado'}
                            name={'estado'}
                            setValue={e => handleChange(e, 'estado')}
                            value={state.estado}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'País'}
                            name={'pais'}
                            setValue={e => handleChange(e, 'pais')}
                            value={state.pais}
                        />
                        <Input
                            className={'col-span-1'}
                            mask={'cellphone'}
                            label={'Telefone'}
                            name={'telefone'}
                            setValue={e => handleChange(e, 'telefone')}
                            value={state.telefone}
                        />
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}