import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import clientesApi from "../../../../../services/clientesApi";
import ViaCepService from "../../../../../services/viaCepApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";

export interface IInformacoesContato{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function InformacoesContato({processo, setProcesso, setTelaNumero, telanumero}: IInformacoesContato ) {
    const [state, setState] = useState({
        processo_id: processo.id,
        id: '',
        endereco: '',
        cidade: '',
        estado: '',
        cep: '',
        pais_natal: '',
        telefone: '',
        telefone_secundario: '',
        telefone_trabalho: '',
        email: '',
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getData = async () => {
        const {data, isError} = await clientesApi.one(processo.cliente_id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState({
                    id: response.id,
                    processo_id: processo.id,
                    cep: response.cep,
                    endereco: response.endereco,
                    cidade: response.cidade,
                    estado: response.estado,
                    pais_natal: response.pais_natal,
                    telefone: response.telefone,
                    telefone_secundario: response.telefone_secundario,
                    telefone_trabalho: response.telefone_trabalho,
                    email: response.usuario.email,
                })
            }
        }
    }
    useEffect(() => {
        getData()
    }, []);
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: removeDiacritics(cepResponse.data.logradouro),
                    cidade: removeDiacritics(cepResponse.data.localidade)+' '+removeDiacritics(cepResponse.data.uf),
                    pais_natal: 'Brasil',

                });
            }
        });
    }

    function removeDiacritics(value: string): string {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, ''); // Remove diacríticos como acentos e cedilha
    }

    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = await clientesApi.update(state.id, state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (processo.percet_conclusao === 20.00){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Informações contato'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>

                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            large={true}
                            required={true}
                            className={'md:col-span-1'}
                            label={'CEP'}
                            name={'cep'}

                            setValue={e => handleChange(e, 'cep')}
                            onBlur={(e: any) => handleChangeCep(e)}
                            value={state.cep}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            required={true}
                            label={'Endereço'}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                        />
                        <Input
                            required={true}
                            large={true}
                            className={'md:col-span-1'}
                            label={'Cidade'}
                            name={'cidade'}
                            setValue={e => handleChange(e,'cidade')}
                            value={state.cidade}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            large={true}
                            className={'md:col-span-1'}
                            label={'Estado'}
                            name={'estado'}
                            setValue={e => handleChange(e,'estado')}
                            value={state.estado}
                        />
                        <Input
                            required={true}
                            large={true}
                            className={'md:col-span-1'}
                            label={'País'}
                            name={'pais_natal'}
                            setValue={e => handleChange(e,'pais_natal')}
                            value={state.pais_natal}
                        />
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone celular'}
                            placeholder={'Telefone celular'}
                            large={true}
                            name={'telefone'}
                            setValue={e => handleChange(e,'telefone')}
                            value={state.telefone}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone residencial'}
                            placeholder={'Telefone residencial'}
                            large={true}
                            name={'telefone_secundario'}
                            setValue={e => handleChange(e,'telefone_secundario')}
                            value={state.telefone_secundario}
                        />
                        <Input
                            mask={'cellphone'}
                            className={'col-span-1'}
                            label={'Telefone do trabalho'}
                            placeholder={'Telefone do trabalho'}
                            large={true}
                            name={'telefone_trabalho'}
                            setValue={e => handleChange(e,'telefone_trabalho')}
                            value={state.telefone_trabalho}
                        />
                        <Input
                            required={true}
                            large={true}
                            className={'md:col-span-1'}
                            label={'E-mail'}
                            name={'email'}
                            setValue={e => handleChange(e,'email')}
                            value={state.email}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}