import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import InputSelect from "../../../../../components/input/InputSelect";
import Input from "../../../../../components/input/Input";
import ViaCepService from "../../../../../services/viaCepApi";
import custeioApi from "../../../../../services/custeioApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface ICusteioViagem{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function CusteioViagem({processo, setProcesso, setTelaNumero, telanumero}: ICusteioViagem) {

    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        quem_custeara: 'eu',
        nome: '',
        sobrenome: '',
        telefone: '',
        email: '',
        endereco: '',
        cidade: '',
        estado: '',
        cep: '',
        pais: '',
        parte_empresa: 1,
        relacao: '',
    });
    const [tela, setTela] = useState(4)
    const cepSrv = new ViaCepService();
    const quemCustearaList = [
        { value: 'eu', label: 'Eu mesmo(a)' },
        { value: 'outra_pessoa', label: 'Outra pessoa' },
        { value: 'empresa', label: 'Empresa / Organização' },
    ];
    const relacaoList = [
        { value: 'pais', label: 'Pai ou mãe' },
        { value: 'conjuge', label: 'Cônjuge' },
        { value: 'filho', label: 'Filho(a)' },
        { value: 'outro', label: 'Outro tipo de parentesco' },
        { value: 'amigo', label: 'Amigo' },
    ]
    const handleChange = (value: any, input: string) => {
        if (input === 'quem_custeara'){
            setState({
                id: '',
                processo_id: processo.id,
                quem_custeara: value,
                nome: '',
                sobrenome: '',
                telefone: '',
                email: '',
                endereco: '',
                cidade: '',
                estado: '',
                cep: '',
                pais: '',
                parte_empresa: 1,
                relacao: '',
            })
        }else {
            setState({...state, [input]: value});
        }
    };
    useEffect(() => {
        getDataCusteio();
        if (processo.motivo_viagem === 'estudo_carga_maior'){
            setTela(telanumero-1);
        }
    },[])
    const getDataCusteio = async () => {
        const {data, isError} = await custeioApi.one(processo.id);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        const response = data.data;
        if (Object.values(response).length){
            setState(response);
        }

    }
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: removeDiacritics(cepResponse.data.logradouro)+' '+removeDiacritics(cepResponse.data.bairro),
                    cidade: removeDiacritics(cepResponse.data.localidade),
                    estado: removeDiacritics(cepResponse.data.uf),
                });
            }
        });
    }

    function removeDiacritics(value: string): string {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, ''); // Remove diacríticos como acentos e cedilha
    }

    async function onSubmit() {
        if (state.quem_custeara === 'eu'){
            setTelaNumero(telanumero+1);
        }else{
            Alert.await("Salvando dados");
            const {data, isError} = state.id ? await custeioApi.update(state.id, state) : await custeioApi.create(state);
            if (isError){
                if (typeof data.data === 'object') {
                    Object.keys(data.data.errors).forEach(function(key, index) {
                        Alert.error(data.data.errors[key][0]);
                    });
                    return;
                }
                return Alert.error(data.data.message? data.data.message : data.data);
            }
            if (!state.id){
                const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
                await processosApi.update(processo.id, {percet_conclusao: percent})
                setProcesso({...processo, 'percet_conclusao': percent})
            }
            Alert.close();
            window.scrollTo(0, 0);
            setTelaNumero(telanumero + 1);
        }
    }
    return(
        <>
            <HeaderForm
                titulo={'Custeio da viagem'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Responsavel pelo custeio da viagem"}
                                selected={quemCustearaList.filter((opt: any) => opt.value === state.quem_custeara)}
                                options={quemCustearaList}
                                onChangeSelect={(e: any) => handleChange(e.value, 'quem_custeara')}
                            />

                        </div>
                    </div>
                    {
                        state.quem_custeara === 'eu' ? '' :
                        state.quem_custeara === 'outra_pessoa' ?
                            <div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'col-span-1'}
                                        label={'Nome '}
                                        placeholder={'Nome'}
                                        large={true}
                                        name={'nome'}
                                        setValue={e => handleChange(e,'nome')}
                                        value={state.nome}
                                        required
                                    />
                                    <Input
                                        className={'col-span-1'}
                                        label={'sobrenome '}
                                        placeholder={'sobrenome'}
                                        large={true}
                                        name={'sobrenome'}
                                        setValue={e => handleChange(e,'sobrenome')}
                                        value={state.sobrenome}
                                        required
                                    />
                                    <Input
                                        className={'col-span-1'}
                                        label={'Email '}
                                        placeholder={'Email'}
                                        large={true}
                                        name={'email'}
                                        setValue={e => handleChange(e,'email')}
                                        value={state.email}
                                        required
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'col-span-1'}
                                        label={'Telefone '}
                                        placeholder={'Telefone'}
                                        large={true}
                                        mask={'cellphone'}
                                        name={'nome'}
                                        setValue={e => handleChange(e,'telefone')}
                                        value={state.telefone}
                                        required
                                    />
                                    <div className={'col-span-1'}>
                                        <InputSelect
                                            label={"Relação com você"}
                                            selected={relacaoList.filter((opt: any) => opt.value === state.relacao)}
                                            options={relacaoList}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'relacao')}
                                        />

                                    </div>
                                    <Input
                                        className={'col-span-1'}
                                        label={'Endereço completo '}
                                        placeholder={'Endereço'}
                                        large={true}
                                        name={'endereco'}
                                        setValue={e => handleChange(e,'endereco')}
                                        value={state.endereco}
                                        required
                                    />
                                </div>
                            </div>
                            :
                            <div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'col-span-2'}
                                        label={'Nome da empresa/organização '}
                                        placeholder={'Nome'}
                                        large={true}
                                        name={'nome'}
                                        setValue={e => handleChange(e,'nome')}
                                        value={state.nome}
                                        required
                                    />
                                    <Input
                                        className={'col-span-1'}
                                        label={'Telefone '}
                                        placeholder={'Telefone'}
                                        large={true}
                                        mask={'cellphone'}
                                        name={'nome'}
                                        setValue={e => handleChange(e,'telefone')}
                                        value={state.telefone}
                                        required
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        label={'CEP'}
                                        name={'cep'}

                                        setValue={e => handleChange(e, 'cep')}
                                        onBlur={(e: any) => handleChangeCep(e)}
                                        value={state.cep}
                                    />
                                    <Input
                                        className={'md:col-span-2'}
                                        label={'Endereço'}
                                        placeholder={'Endereço'}
                                        large={true}
                                        name={'endereco'}
                                        setValue={e => handleChange(e,'endereco')}
                                        value={state.endereco}
                                        required
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        label={'Cidade'}
                                        name={'cidade'}
                                        setValue={e => handleChange(e,'cidade')}
                                        value={state.cidade}
                                    />
                                    <Input
                                        className={'md:col-span-1'}
                                        label={'Estado'}
                                        name={'estado'}
                                        setValue={e => handleChange(e,'estado')}
                                        value={state.estado}
                                    />
                                    <div>
                                        <div className={'flex flex-col'}>
                                            <label
                                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                Você faz parte do quadro de colaboradores?
                                            </label>
                                            <div className={'flex  gap-4 mt-[1rem]'}>
                                                <div className={'flex'}>
                                                    <input
                                                        onChange={e => handleChange(1,'parte_empresa')}
                                                        value={1}
                                                        id="checked-viaja_junto-s"
                                                        type="radio"
                                                        checked={state.parte_empresa === 1 }
                                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label
                                                        htmlFor="checked-parte_empresa-s"
                                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Sim
                                                    </label>
                                                </div>
                                                <div className={'flex'}>
                                                    <input
                                                        checked={state.parte_empresa === 0 }
                                                        onChange={e => handleChange(0,'parte_empresa')}
                                                        id="checked-parte_empresa-n"
                                                        type="radio"
                                                        value={0}
                                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                    />
                                                    <label
                                                        htmlFor="checked-parte_empresa-n"
                                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Não
                                                    </label>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                    }
                </div>


                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(tela)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>

        </>
    )
}