import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import processosApi from "../../../../../services/processosApi";
import {aw} from "@fullcalendar/core/internal-common";
import Alert from "../../../../../helpers/Alert";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import clientesApi from "../../../../../services/clientesApi";

export interface IDadosAcompantes{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosAcompantes({processo, setTelaNumero, telanumero}: IDadosAcompantes) {
    const [acompanhantesNome, setAcompanhantesNome] = useState("");
    const [acompanhantesVistosOpt, setAcompanhantesVistosOpt]: any = useState([]);
    const [acompanhantesVistosSelected, setAcompanhantesVistosSelected]: any = useState([]);

    /*useEffect(()=>{
        getMembros()
    },[])
    const getMembros = async () => {

        const {data, isError} = await processosApi.membros(
            processo.id_processo_responsavel ?
                processo.id_processo_responsavel
                :
                processo.id
        );
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        const response = data.data;

        if (Object.values(response).length){
            const arrayVistosOpt: { value: any; label: any; }[] = [];
            const arrayVistosSelected: { value: any; label: any; }[] = [];
            let nome = '';
            response.map((res: any, index: number) => {

                if (res.possui_visto){
                    arrayVistosSelected.push({ value: res.id, label: res.nome })
                }
                arrayVistosOpt.push({ value: res.id, label: res.nome })
                if (index){
                    if (!nome.length){
                        nome += res.nome;
                    }else{
                        nome += ', '+res.nome;
                    }
                }
            });
            setAcompanhantesVistosSelected(arrayVistosSelected);
            setAcompanhantesVistosOpt(arrayVistosOpt);
            setAcompanhantesNome(nome);
        }else{
            setAcompanhantesNome('VOCÊ NÃO POSSUI ACOMPANHANTES');
        }
    }*/


    useEffect(() => {getDataProcesso()}, [])


    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                processo.percet_conclusao = data.data.percet_conclusao
                console.log(data.data.acompanhantes_com_visto);
                setAcompanhantesNome(data.data.acompanhantes_com_visto)
            }
        }
    }

    // Função para sanitizar o texto do textarea
    const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let value = event.target.value;

        // Remove todos os diacríticos, incluindo acentos, cedilha e crase
        value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, '');

        setAcompanhantesNome(value);
    };

    async function onSubmit() {
        const state = {
            acompanhantes_com_visto: acompanhantesNome,
            percet_conclusao: 100
        }
        if (processo.percet_conclusao === 0){
            state.percet_conclusao = (telanumero * 100) / quantidade[processo.tipo_visto_id]
        }
        const {data, isError} = await processosApi.update(processo.id, state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1)





        /*const processo_id = processo.id_processo_responsavel ? processo.id_processo_responsavel : processo.id;
        const clientes_id = acompanhantesVistosSelected.map((acompanhante: any) => acompanhante.value);
        const params = {
            'processo_id': processo_id,
            'clientes_id': clientes_id,
        }

        const {data, isError} = await clientesApi.alteraVistoClientes(params);

        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        setTelaNumero(telanumero+1)*/
    }

    return(
        <>
            <HeaderForm
                titulo={'Acompanhantes'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'grid md:grid-cols-1  p-5'}>
                    <label>Caso haja acompanhantes que já tenham vistos, <b>adicionar o nome completo e relação com você</b> </label>
                    <textarea value={acompanhantesNome} onChange={handleTextareaChange} className={'border p-2'} rows={5}>
                        {acompanhantesNome}
                    </textarea>
                </div>
                {/*<div className={'flex flex-col col-span-1'}>
                    <label
                        className="text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                        Você já possuiu outro nome?
                    </label>
                    <div className={'flex  gap-4 mt-[1rem]'}>
                        <div className={'flex'}>
                            <input
                                onChange={e => handleChange(1,'possui_nomes_anteriores')}
                                value={1}
                                id="checked-possui_nomes_anteriores-s"
                                type="radio"
                                checked={state.possui_nomes_anteriores === 1 }
                                className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="checked-possui_nomes_anteriores-s"
                                className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Sim
                            </label>
                        </div>
                        <div className={'flex'}>
                            <input
                                checked={state.possui_nomes_anteriores === 0 }
                                onChange={e => console.log(0,'possui_nomes_anteriores')}
                                id="checked-possui_nomes_anteriores-n"
                                type="radio"
                                value={0}
                                className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                                htmlFor="checked-possui_nomes_anteriores-n"
                                className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Não
                            </label>
                        </div>
                    </div>
                </div>*/}
                {/*<div className={'p-5'}>
                    <Input
                        className={'md:col-span-1'}
                        label={'Acompanhantes'}
                        name={'acompanhantes'}
                        disabled={true}
                        value={acompanhantesNome}
                    />
                </div>*/}
                {/*<div className={'grid md:grid-cols-1  p-5'}>
                    <InputSelect
                        placeholder={'Selecione'}
                        label={"Membros que já possuem vistos"}
                        isMulti={true}
                        selected={acompanhantesVistosSelected}
                        options={acompanhantesVistosOpt}
                        onChangeSelect={(e: any) => setAcompanhantesVistosSelected(e)}
                    />
                </div>*/}
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}